//js
require("./js/slider");
require("./js/scroll");
require("./js/vendor/jquery.scrollto");
require("./js/helper");
require("./js/navigation");
require("./js/main");

//fonts
require("./fonts/IBMPlexSans/stylesheet.css");
require("./fonts/Aldrich/stylesheet.css");

// css
import "normalize.css";
require("./sass/style.scss");
